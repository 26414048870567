@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
body {
    /* background-color: #f0f2f5; */
    /* background-color: #f9f9f9; */
    font-family: "Ubuntu", sans-serif;
}

.bg-custom {
    /* background-color: #FFF3DA;
    border-bottom-left-radius: 2% 15%;
    border-bottom-right-radius: 2% 15%;
    overflow: hidden; */
    /* background-image: url('./images/bgcover.png'); */
}


/* .bg-bottom {
    background-color: #FFB923;
} */

.max-width {
    max-width: 920px;
}

.form-radio+label span {
    transition: background 0.2s, transform 0.2s;
}

.form-radio+label span:hover,
.form-radio+label:hover span {
    transform: scale(1.2);
}

.form-radio:checked+label span {
    background-color: #3490dc;
    box-shadow: 0px 0px 0px 2px white inset;
}

.form-radio:checked+label {
    color: #3490dc;
}

.img {
    background: url(https://www.numerama.com/wp-content/uploads/2020/08/we-love-maths.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.truncate-2-lines {
    max-height: 3.6em;
    /* double the size of line-height */
    line-height: 1.8em;
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
}


/* Pie */

circle {
    transition: all 0.7s ease-in-out;
}

.physics {
    background-image: url("./assets/images/Physics.png");
    background-repeat: no-repeat;
    background-position: top right;
}

.hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hide-scroll-bar::-webkit-scrollbar {
    display: none;
}

.test {
    background: linear-gradient(#1b69b3 30%, rgba(27, 105, 179, 0.88)), linear-gradient(rgba(27, 105, 179, 0.88), #f3f6f9 70%) 0 100%, radial-gradient( farthest-side at 50% 0, rgba(27, 105, 179, 0.88), rgba(27, 105, 179, 0.88)), radial-gradient( farthest-side at 50% 100%, rgba(27, 105, 179, 0.88), rgba(27, 105, 179, 0.88)) 0 100%;
}

input[type="username"] {
    text-transform: lowercase;
}

.required::after {
    content: "*";
    color: red;
}


/* bell notif */